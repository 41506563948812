/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components), {ProjectCard} = _components;
  if (!ProjectCard) _missingMdxReference("ProjectCard", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Projects"), "\n", React.createElement(ProjectCard, {
    title: "VectorWave",
    link: "https://vectorwave.markchall.com",
    bg: "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
  }, React.createElement(_components.p, null, "A full-stack TypeScript application that leverages LLMs and vector databases\nto automate customer support for businesses.")), "\n", React.createElement(ProjectCard, {
    title: "Go User RestAPI",
    link: "https://github.com/Mark-C-Hall/GoUserRestAPI",
    bg: "linear-gradient(to right, #3585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "A minimalistic REST API microservice built with Go for user authentication.")), "\n", React.createElement(ProjectCard, {
    title: "Sourdough Website",
    link: "https://sourdough.markchall.com",
    bg: "linear-gradient(to right, #009245 0%, #FCEE21 100%)"
  }, React.createElement(_components.p, null, "A simple site about sourdough bread making for my web development class. The\nsite was made using vanilla HTML, CSS, and JS.")), "\n", React.createElement(ProjectCard, {
    title: "Discord Bot for ASU Study Group",
    link: "https://github.com/Mark-C-Hall/Poly-Discord-Bot",
    bg: "linear-gradient(to right, #FF8C00 0%, #FFFF00 100%)"
  }, React.createElement(_components.p, null, "A simple Discord Bot which sends a random Reddit image to our study group's\nDiscord server.")), "\n", React.createElement(ProjectCard, {
    title: "Galactic Diaries",
    link: "https://github.com/Mark-C-Hall/Galactic-Diaries",
    bg: "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
  }, React.createElement(_components.p, null, "A full-stack JS application in which users can create a simple blog post and\ncomment to others. My Capston Project for my associate's degree.")), "\n", React.createElement(ProjectCard, {
    title: "Java Addition Quiz",
    link: "https://github.com/Mark-C-Hall/AdditionQuiz",
    bg: "linear-gradient(to right, #3585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "A desktop application demonstrating a 3-tiered architecture using Java for an\nAdvanced Java course final project.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
